import React, { useState, useContext, useEffect } from "react";

/**
 * mui
 */
import {
  Typography,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SignalCellularNoSimIcon from "@mui/icons-material/SignalCellularNoSim";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { green, red } from "@mui/material/colors";

/**
 * components
 */
import LinearProgress from "../core/barProgress";
import Buttons from "../core/button";
import PatientList from "../patient/patientList";
import PatientCase from "../patient/patientCase";
import { Search } from "src/components/core/Search";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

/**
 * external
 */
import axios from "axios";

//Styles
import "./branchOffice.scss";

export const BranchOffice = (props) => {
  const [showImage, setShowImage] = useState(false);
  const [pacientData, setPacientData] = useState();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const [headCells, setHeadCells] = useState([]);

  const {
    currentYear,
    general,
    monthIndex,
    sucIndex,
    setShow,
    setSucIndex,
    setMonthIndex,
  } = props;

  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor, secondaryColor } = channelParameters;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [isAdult, setAdult] = useState(null);
  const [isLoader, setLoader] = useState(false);

  useEffect(() => {
    let header = [];
    if (!isAdult) {
      header = [
        {
          id: "name",
          numeric: false,
          disablePadding: false,
          label: "Nombre",
          sx: { minWidth: "300px" },
        },
        {
          id: "pacient_id",
          numeric: false,
          disablePadding: false,
          label: "Identificador",
          sx: { minWidth: "190px" },
        },
        {
          id: "age",
          numeric: true,
          disablePadding: false,
          label: "Edad",
          sx: { minWidth: "160px" },
          field: {
            id: "enhancedAge",
            type: "number",
            name: "enhancedAge",
            value: "",
          },
        },
        {
          id: "date",
          numeric: true,
          disablePadding: false,
          label: "Fecha",
          sx: { minWidth: "220px" },
          field: {
            id: "date",
            type: "date",
            name: "date",
            value: "",
          },
        },
        {
          id: "referable",
          numeric: false,
          disablePadding: false,
          label: "Pre-diagnóstico",
          sx: { minWidth: "190px" },
          field: {
            id: "referable",
            type: "selection",
            name: "referable",
            value: "",
            options: [
              {
                component: "--Seleccione--",
                value: "",
              },
              {
                component: "Referible",
                value: "referable",
              },
              {
                component: "No referible",
                value: "non-referable",
              },
            ],
          },
        },
        {
          id: "diseases",
          numeric: false,
          disablePadding: false,
          label: "Diagnóstico",
          sx: { minWidth: "270px" },
        },
        {
          id: "follow_up_date",
          numeric: true,
          disablePadding: false,
          label: "Fecha de Seguimiento",
          sx: { minWidth: "220px" },
          field: {
            id: "follow_up_date",
            type: "date",
            name: "follow_up_date",
            value: "",
          },
        },
        {
          id: "quality_images",
          numeric: false,
          disablePadding: false,
          label: "Imágenes de calidad",
          sx: { minWidth: "220px" },
          field: {
            id: "quality_images",
            type: "selection",
            name: "quality_images",
            value: "",
            options: [
              {
                component: "--Seleccione--",
                value: "",
              },
              {
                component: (
                  <CheckIcon fontSize="small" style={{ color: green[500] }} />
                ),
                value: true,
              },
              {
                component: (
                  <CloseIcon fontSize="small" style={{ color: red[500] }} />
                ),
                value: false,
              },
            ],
          },
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: "Etiquetado",
          sx: { minWidth: "150px" },
          field: {
            id: "status",
            type: "selection",
            name: "status",
            value: "",
            options: [
              {
                component: "--Seleccione--",
                value: "",
              },
              {
                component: (
                  <RemoveCircleOutlineIcon fontSize="small" color="disabled" />
                ),
                value: "pending",
              },
              {
                component: (
                  <CheckCircleIcon
                    fontSize="small"
                    style={{ color: green[500] }}
                  />
                ),
                value: "evaluated",
              },
              {
                component: <SignalCellularNoSimIcon color="disabled" />,
                value: null,
              },
            ],
          },
        },
      ];
    } else {
      header = [
        {
          id: "name",
          numeric: false,
          disablePadding: false,
          label: "Nombre",
          sx: { minWidth: "300px" },
        },
        {
          id: "pacient_id",
          numeric: false,
          disablePadding: false,
          label: "Identificador",
          sx: { minWidth: "190px" },
        },
        {
          id: "age",
          numeric: true,
          disablePadding: false,
          label: "Edad",
          sx: { minWidth: "160px" },
          field: {
            id: "enhancedAge",
            type: "number",
            name: "enhancedAge",
            value: "",
          },
        },
        {
          id: "date",
          numeric: true,
          disablePadding: false,
          label: "Fecha",
          sx: { minWidth: "220px" },
          field: {
            id: "date",
            type: "date",
            name: "date",
            value: "",
          },
        },
        {
          id: "referable",
          numeric: false,
          disablePadding: false,
          label: "Pre-diagnóstico",
          sx: { minWidth: "190px" },
          field: {
            id: "referable",
            type: "selection",
            name: "referable",
            value: "",
            options: [
              {
                component: "--Seleccione--",
                value: "",
              },
              {
                component: "Referible",
                value: "referable",
              },
              {
                component: "No referible",
                value: "non-referable",
              },
            ],
          },
        },
        {
          id: "diseases",
          numeric: false,
          disablePadding: false,
          label: "Diagnóstico",
          sx: { minWidth: "270px" },
        },
        {
          id: "quality_images",
          numeric: false,
          disablePadding: false,
          label: "Imágenes de calidad",
          sx: { minWidth: "220px" },
          field: {
            id: "quality_images",
            type: "selection",
            name: "quality_images",
            value: "",
            options: [
              {
                component: "--Seleccione--",
                value: "",
              },
              {
                component: (
                  <CheckIcon fontSize="small" style={{ color: green[500] }} />
                ),
                value: true,
              },
              {
                component: (
                  <CloseIcon fontSize="small" style={{ color: red[500] }} />
                ),
                value: false,
              },
            ],
          },
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: "Etiquetado",
          sx: { minWidth: "150px" },
          field: {
            id: "status",
            type: "selection",
            name: "status",
            value: "",
            options: [
              {
                component: "--Seleccione--",
                value: "",
              },
              {
                component: (
                  <RemoveCircleOutlineIcon fontSize="small" color="disabled" />
                ),
                value: "pending",
              },
              {
                component: (
                  <CheckCircleIcon
                    fontSize="small"
                    style={{ color: green[500] }}
                  />
                ),
                value: "evaluated",
              },
              {
                component: <SignalCellularNoSimIcon color="disabled" />,
                value: null,
              },
            ],
          },
        },
      ];
    }
    setHeadCells(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdult]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setRows([]);
    setAdult(null);
    const data = general[monthIndex];
    const { month, year, sucursal_detail } = data;
    const sucursal = sucursal_detail[sucIndex];
    const { poc_id } = sucursal;
    let block = 0;
    const source = axios.CancelToken.source();
    setLoader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getInteractionsData = async () => {
      const url = `${process.env.REACT_APP_API_GET_INTERACTIONS_DATA}?year=${year}&month=${month}&poc_id=${poc_id}&block=${block}`;
      await axios
        .get(
          url,
          {
            cancelToken: source.token,
          }
        )
        .then((response) => {
          const { finish, data } = response["data"]["output"];
          setData(mapAge(data));
          if (!finish) {
            block = block + 1;
            getInteractionsData();
          } else setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getInteractionsData();
    return () => source.cancel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sucIndex, monthIndex]);

  useEffect(() => {
    if (data.length > 0) {
      if (isAdult === null) {
        const patient = data[0];
        const { branch } = patient;
        const isAdult = !branch || branch === "retinia-adult";
        setAdult(isAdult);
      }
      const info = [...rows];
      setRows(info.concat(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (rows.length > 0) filters(rows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, headCells, rows]);

  const filters = (rows) => {
    let filters = [];
    if (headCells) {
      let data = [...headCells];
      filters = data
        .filter((cell) => {
          if (cell.field && cell.field.value !== "") {
            return cell;
          } else return null;
        })
        .map((row) => {
          const { field } = row;
          const { name, value, type } = field;
          if (type !== "date") {
            return (item) => item[name] === value;
          } else {
            return (item) => {
              if (!item[name]) return null;
              const dateHours = convertDateToUTC(new Date(item[name])).setHours(
                0,
                0,
                0,
                0
              );
              const valueHours = value.setHours(0, 0, 0, 0);
              return dateHours === valueHours;
            };
          }
        });
    }

    if (search || filters.length > 0) {
      const results = [...rows];
      let findSearch = results.filter((row) => {
        const { name, last_name, pacient_id, diseases } = row;
        const value = search.toLowerCase().trim();

        if (
          name.toLowerCase().includes(value) ||
          last_name.toLowerCase().includes(value) ||
          pacient_id.toLowerCase().includes(value) ||
          diseases.toLowerCase().includes(value)
        ) {
          return row;
        } else return null;
      });

      if (filters.length > 0)
        findSearch = findSearch.filter((item) =>
          filters.every((conditional) => conditional(item))
        );

      setFilteredRows(findSearch);
      setPage(0);
    } else {
      setFilteredRows(rows);
    }
  };

  const convertDateToUTC = (date) => {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  };

  const mapAge = (data) => {
    let patients = [...data];

    patients.forEach((value) => {
      let time = "años";
      const { age } = value;
      let round = age;
      if (age <= 2) {
        time = "meses";
        round = round * 12;
        round = Math.round(round);
        value.enhancedAge = round;
        if (round === 1) time = "mes";
      } else value.enhancedAge = Math.round(round);
      value.time = time;
      return value;
    });
    return patients;
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchKeyDown = (e) => {
    if (e.code === "Enter") {
      const value = e.currentTarget.value;
      setSearch(value);
    }
  };

  const handleSearchClean = (e) => {
    setSearch("");
  };

  const handleChange = (event) => {
    setSucIndex(event.target.value);
    setPage(0);
  };
  const handleChangeMonth = (event) => {
    setMonthIndex(event.target.value);
    setPage(0);
  };

  const handleFieldChange = (name, value) => {
    let data = [...headCells];
    let find = findHeadCells(name, data);
    if (find) find.field.value = value;
    setHeadCells(data);
    setPage(0);
  };

  const findHeadCells = (name, data) => {
    return data.find((data) => data?.field?.id === name);
  };

  return (
    <>
      {showImage ? (
        <PatientCase
          setShowImage={setShowImage}
          pacientData={pacientData}
          sucData={general[monthIndex].sucursal_detail[sucIndex]}
        />
      ) : (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoader}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <CircularProgress color="secondary" />
              <Typography
                sx={{ fontWeight: 600, color: secondaryColor }}
                variant="h6"
              >
                {"Cargando..."}
              </Typography>
            </Box>
          </Backdrop>
          <section className="wrapper_branch mg t2">
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={6} md={2}>
                <FormControl className="mg b3" variant="filled">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Mes
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={monthIndex}
                    onChange={handleChangeMonth}
                    label="Age"
                  >
                    {general.map((month, index) => {
                      if (
                        Object.keys(month["sucursal_detail"]).includes(sucIndex)
                      ) {
                        return (
                          <MenuItem key={index} value={index}>
                            {month.month_name} {currentYear}
                            {month.year}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="filled">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Sucursal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={sucIndex}
                    onChange={handleChange}
                    label=""
                  >
                    {Object.keys(general[monthIndex].sucursal_detail).map(
                      (suc, index) => {
                        return (
                          <MenuItem key={index} value={suc}>
                            {suc}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {filteredRows && (
                <React.Fragment>
                  <Grid item xs={12} sm={6} md={8}>
                    <Typography
                      variant="h6"
                      className="mg m0"
                      sx={{ fontWeight: "bold" }}
                    >
                      {"% de pacientes con pre-diagnóstico referible"}
                    </Typography>
                    <LinearProgress
                      color={primaryColor}
                      progress={
                        general[monthIndex].sucursal_detail[sucIndex].percentage
                      }
                      total={
                        general[monthIndex].sucursal_detail[sucIndex].total
                      }
                      ref_total={
                        general[monthIndex].sucursal_detail[sucIndex][
                          "ref_total"
                        ]
                      }
                      ref_percentage={
                        general[monthIndex].sucursal_detail[sucIndex][
                          "ref_percentage"
                        ]
                      }
                      bar_height={35}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} className="text-center">
                    <Typography
                      variant="h6"
                      className="mg m0"
                      sx={{ fontWeight: "bold" }}
                    >
                      {"% Etiquetado"}
                    </Typography>
                    <Typography className="f_normal mg m0 f_size large">
                      {general[monthIndex].sucursal_detail[sucIndex].percentage}
                      %
                    </Typography>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid container justifyContent={"center"} className="mg t5">
              {filteredRows && (
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={5} sx={{ marginBottom: "10px" }}>
                      <Search
                        search={search}
                        onChange={handleSearchChange}
                        onKeyDown={handleSearchKeyDown}
                        onClean={handleSearchClean}
                      />
                    </Grid>
                  </Grid>
                  <PatientList
                    isAdult={isAdult}
                    bgColor={primaryColor}
                    rows={filteredRows}
                    setShowImage={setShowImage}
                    setPacientData={setPacientData}
                    page={page}
                    setPage={setPage}
                    handleFieldChange={handleFieldChange}
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                  />
                </Grid>
              )}
            </Grid>
            <Box
              className="mg t5 button-component"
              onClick={() => setShow(false)}
            >
              <Buttons title="Regresar" type="button" colors={secondaryColor} />
            </Box>
          </section>
        </>
      )}
    </>
  );
};
