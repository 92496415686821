//Built-in
import React, { useEffect, useState, memo, useContext } from "react";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";

//External
import Magnifier from "react-magnifier";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

const ImageMagnifier = ({ image, branch }) => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { secondaryColor } = channelParameters;

  const useStyles = makeStyles((theme) => ({
    switch_track: {
      backgroundColor: "#ffffff",
    },
    switch_base: {
      color: "#ffffff",
      "&.Mui-disabled": {
        color: "#ffffff",
      },
      "&.Mui-checked": {
        color: secondaryColor,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: secondaryColor,
      },
    },
    switch_primary: {
      "&.Mui-checked": {
        color: secondaryColor,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: secondaryColor,
      },
    },
  }));

  const classes = useStyles();

  const [preprocessed, setPreprocessed] = useState(true);

  const { url, preprocessedUrl } = image;

  const isAdult = !branch || branch === "retinia-adult";

  useEffect(() => {
    setPreprocessed(isAdult ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const onChangeSwitch = (event) => {
    setPreprocessed(event.target.checked);
  };
  const onChangeDiv = (event) => {
    if (preprocessedUrl) setPreprocessed(!preprocessed);
  };

  return (
    <div className="box_view_magnifier">
      <div className="box_magnifier" onClick={(event) => onChangeDiv(event)}>
        <Magnifier
          src={preprocessedUrl && preprocessed ? preprocessedUrl : url}
          mgWidth={250}
          mgHeight={250}
          zoomFactor={0.8}
        />
        {preprocessedUrl && (
          <FormControlLabel
            className="preprocessed"
            control={
              <Switch
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
                checked={preprocessed}
                onChange={(event) => onChangeSwitch(event)}
                name="preprocessed"
              />
            }
            label={<Typography className="label">{"procesada"}</Typography>}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ImageMagnifier);
