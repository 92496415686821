import React, { useState, useContext } from "react";

/**
 * mui
 */

import { makeStyles } from "@mui/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {
  IconButton,
  FilledInput,
  InputLabel,
  InputAdornment,
  FormControl,
  TextField,
  Box,
  Typography,
} from "@mui/material";

/**
 * components
 */
import Buttons from "../core/button";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

/**
 * external
 */
import axios from "axios";
import { sha256 } from "js-sha256";
import { useNavigate, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

/**
 * internal
 */
import "./login.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
}));

export const Login = () => {
  let navigate = useNavigate();

  let { channelID } = useParams();

  const [errorMessage, setError] = useState("");
  const [loginError, handleError] = useState(false);
  const [isLoader, setLoader] = useState(false);

  const classes = useStyles();

  const [values, setValues] = useState({
    amount: "",
    doctor_username: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const { channelParameters } = useContext(ChannelParametersContext);
  const { secondaryColor } = channelParameters;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const sendData = async (event) => {
    setLoader(true);
    event.preventDefault();
    const url = process.env.REACT_APP_API_POST_LOGIN;
    await axios
      .post(url,
        {
          doctor_username: values.doctor_username,
          password: sha256("televal" + values.password),
        }
      )
      .then((res) => {
        setLoader(false);
        localStorage.setItem("token", res["data"].output);
        navigate(`/${channelID}/home`);
      })
      .catch((err) => {
        setLoader(false);
        handleError(true);
        setError(err.response.data.message);
      });
  };

  return (
    <section className="wrapper_login">
      <Box className="box_form">
        <form onSubmit={sendData}>
          <Box className={classes.root}>
            <Typography variant="h6">{"Ingresa tu usuario:"}</Typography>
            <TextField
              className="mg t1"
              id="filled-basic"
              label="Usuario"
              variant="filled"
              onChange={handleChange("doctor_username")}
            />
          </Box>
          <br />
          <Box className={classes.root}>
            <Typography variant="h6">{"Ingresa tu contraseña:"}</Typography>
            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                Password
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          {loginError && (
            <Typography component="p" className="error-login">
              {errorMessage}
            </Typography>
          )}
          <Box
            className="mg t5 button-component"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {isLoader ? (
              <Oval color={secondaryColor} height={30} width={30} />
            ) : (
              <Buttons
                disabled={
                  values.password.length > 0 &&
                  values.doctor_username.length > 0
                    ? false
                    : true
                }
                title="Continuar"
                type="submit"
                colors={secondaryColor}
              />
            )}
          </Box>
        </form>
      </Box>
    </section>
  );
};
