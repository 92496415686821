//Built-in
import React, { useContext, memo } from "react";
import { makeStyles } from "@mui/styles";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

const ThumbnailImage = ({
  index,
  isActive,
  onClick,
  image,
}) => {
  const { url } = image;

  const { channelParameters } = useContext(ChannelParametersContext);
  const { secondaryColor } = channelParameters;

  const useStyles = makeStyles(() => ({
    thumbnailImage: {
      "&.active, &:hover": {
        boxShadow: `0 0 0 4px ${secondaryColor}`,
      },
    },
  }));

  const classes = useStyles();
  const classItem = isActive ? "active" : "";
  return (
    <div className="item_thumbnail" onClick={(event) => onClick(index)}>
      <div className={"item_thumbnail " + classItem ? classItem : ""}>
        <img
          src={url}
          alt="thumbnail"
          className={classes.thumbnailImage + " " + classItem || ""}
        />
      </div>
    </div>
  );
};

export default memo(ThumbnailImage);
