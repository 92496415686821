//Built-in
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Container,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Typography,
} from "@mui/material";

//External
import axios from "axios";

//Components
import { BranchOffice } from "../branchOffice";
import { Grid as GridLoader } from "react-loader-spinner";
import LinearWithValueLabel from "../core/barProgress";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";

//Styles
import "./home.scss";

export const Home = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState({});
  const [monthIndex, setMonthIndex] = useState(0);
  const [sucIndex, setSucIndex] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const { channelParameters } = useContext(ChannelParametersContext);
  const { secondaryColor } = channelParameters;

  const currentYear = String(new Date().getFullYear()).slice(0, 2);

  const getInteractions = async (token) => {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    const url = process.env.REACT_APP_API_GET_INTERACTIONS_HEAD;
    await axios
      .get(url)
      .then((res) => {
        const data = res["data"]["output"];
        setData(data);
        setSucIndex(Object.keys(data[0]["sucursal_detail"])[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getInteractions(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleChange = (event) => {
    setMonthIndex(event.target.value);
  };

  const components = show ? (
    <BranchOffice
      currentYear={currentYear}
      general={data}
      monthIndex={monthIndex}
      sucIndex={sucIndex}
      setShow={setShow}
      setSucIndex={setSucIndex}
      setMonthIndex={setMonthIndex}
    />
  ) : (
    <section className="wrapper_home ">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {
                "Televal es una herramienta que permite visualizar las imágenes capturadas, la información recolectada y los pre-diagnósticos de tamizajes oftalmológicos. Para ingresar a los casos de un Punto de contacto (POC) específico, haga clic sobre la barra de % de pacientes referidos correspondiente."
              }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Box className="input_select">
              <FormControl variant="filled">
                <InputLabel id="demo-simple-select-outlined-label">
                  Mes
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={monthIndex}
                  onChange={handleChange}
                >
                  {data.length > 0 &&
                    data.map((month, index) => {
                      return (
                        <MenuItem key={index} value={index}>
                          {month.month_name} {currentYear}
                          {month.year}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Box className="wrapper_list_sucursales">
          {!loading ? (
            <>
              <Grid className="ttls_ist_sucursales" container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Typography component="h3" sx={{ fontWeight: "bold" }}>
                    {"Sucursal"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography component="h3" sx={{ fontWeight: "bold" }}>
                    {"% de pacientes con pre-diagnóstico referible"}
                  </Typography>
                </Grid>
                <Grid className="text-center" item xs={12} sm={2}>
                  <Typography component="h3" sx={{ fontWeight: "bold" }}>
                    {"% Etiquetado"}
                  </Typography>
                </Grid>
              </Grid>

              <>
                {Object.keys(data[monthIndex]["sucursal_detail"]).map(
                  (suc, index) => {
                    return (
                      <Box
                        className="cursor-pointer"
                        onClick={() => {
                          setSucIndex(suc);
                          setShow(true);
                        }}
                        key={index}
                      >
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          className="item_list"
                        >
                          <Grid item xs={12} sm={2}>
                            <Typography
                              component="h1"
                              className="f_normal f_size large"
                            >
                              {suc}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <LinearWithValueLabel
                              total={
                                data[monthIndex]["sucursal_detail"][suc][
                                  "total"
                                ]
                              }
                              ref_total={
                                data[monthIndex]["sucursal_detail"][suc][
                                  "ref_total"
                                ]
                              }
                              ref_percentage={
                                data[monthIndex]["sucursal_detail"][suc][
                                  "ref_percentage"
                                ]
                              }
                            />
                          </Grid>
                          <Grid className="text-center" item xs={12} sm={2}>
                            <Typography
                              component="h1"
                              className="f_normal f_size large"
                            >
                              {
                                data[monthIndex]["sucursal_detail"][suc][
                                  "percentage"
                                ]
                              }
                              %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  }
                )}
              </>
            </>
          ) : (
            <Box className="center-spinner">
              <GridLoader
                color={secondaryColor ? secondaryColor : "#FFF"}
                height={80}
                width={80}
              />
            </Box>
          )}
        </Box>
      </Container>
    </section>
  );

  return (
    <Box className="wrapper_main">
      <Box className="section_main trans">{components}</Box>
    </Box>
  );
};
