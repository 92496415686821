//Built-in
import React, { memo, useState, useContext, useEffect } from "react";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";

/**
 * context
 */
import { ChannelParametersContext } from "@prosperia/context.channel-parameters-context";
import PatientEvaluationProvider from "src/context/PatientEvaluationContext";
import PatientFollowProvider from "src/context/PatientFollowContext";

/**
 * components
 */
import PatientEvaluation from "./eyesEvaluations/patientEvaluations";
import PatientClinicalInfo from "./patientClinicalInfo/patientClinicalInfo";
import PatientInfo from "./patientInfo/patientInfo";
import { TabPanel } from "src/components/core/tabPanel";

/**
 * hook
 */
import useInteraction from "src/hook/useInteraction";

/**
 * external
 */
import { Grid } from "react-loader-spinner";

/**
 * internal
 */
import "./patientCase.scss";

const PatientCase = ({ setShowImage, pacientData, sucData }) => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { secondaryColor } = channelParameters;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [value, setValue] = useState(0);

  const { interaction_id, doctor_id, branch, follow_up_code, follow_up_text } =
    pacientData;

  const { eyes, loading, clinicalInfo } = useInteraction({
    interaction_id,
    doctor_id,
    branch,
  });

  const useStyles = makeStyles((theme) => ({
    back: {
      [theme.breakpoints.up(769)]: {
        marginBottom: 35,
      },
      [theme.breakpoints.down(769)]: {
        marginBottom: 20,
      },
    },
  }));

  const classes = useStyles();

  const Spinner = () => {
    return (
      <>
        {loading && (
          <div className="center-spinner">
            <Grid color={secondaryColor} height={80} width={80} />
          </div>
        )}
      </>
    );
  };

  const onChangeTab = (event, value) => {
    setValue(value);
  };

  let catalogue = null;
  if (follow_up_code && follow_up_text) {
    catalogue = { id: follow_up_code, text: follow_up_text };
  }
  return (
    <PatientFollowProvider value={catalogue}>
      <section className="wrapper_user_details">
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Box
            onClick={() => setShowImage(false)}
            display="flex"
            className={classes.back}
            style={{ cursor: "pointer" }}
          >
            <ArrowBackIcon fontSize="small" />
            <Typography
              style={{
                fontWeight: "bold",
                marginLeft: 5,
                fontSize: 13,
              }}
            >
              {"REGRESAR"}
            </Typography>
          </Box>

          <PatientInfo
            pacientData={pacientData}
            color={green[500]}
            branch={branch}
          />
        </Box>

        <div className="sec_user_details">
          {loading ? (
            <Spinner />
          ) : (
            <PatientEvaluationProvider data={eyes} branch={branch}>
              <Tabs
                value={value}
                onChange={onChangeTab}
                textColor="primary"
                indicatorColor="primary"
                aria-label="tabs"
              >
                <Tab label="IMÁGENES" />
                <Tab label="INFORMACIÓN ADICIONAL" />
              </Tabs>
              <TabPanel value={value} index={0} styleBox={{ marginTop: 0 }}>
                {eyes && eyes.length > 0 ? (
                  <PatientEvaluation
                    pacientData={pacientData}
                    sucData={sucData}
                    setShowImage={setShowImage}
                  />
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{ height: "100vh", marginTop: "15%" }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                      No existen imágenes disponibles para el paciente
                    </Typography>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {clinicalInfo && (
                  <PatientClinicalInfo clinicalInfo={clinicalInfo} />
                )}
              </TabPanel>
            </PatientEvaluationProvider>
          )}
        </div>
      </section>
    </PatientFollowProvider>
  );
};

export default memo(PatientCase);
